import { LayoutComponent } from "../layout/layout.component"
import { ErrorComponent } from '../layout/error.component';

export const routes = [
  { path: '', loadChildren: '../login/login.module#LoginModule' },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadChildren: './home/home.module#HomeModule' },
      { path: 'Job', loadChildren: './Job/Job.module#JobModule' },
      { path: 'Roles', loadChildren: './Roles/Roles.module#RolesModule' },
      { path: 'Employee', loadChildren: './Employee/Employee.module#EmployeeModule' },
      { path: 'BP_Account', loadChildren: './BP_Account/BP_Account.module#BPAccontModule' },
      { path: 'BP_Distributor', loadChildren: './BP_Distributor/BP_Distributor.module#BPDistributorModule' },
      { path: 'pof', loadChildren: './pof/pof.module#POFModule' , data: { preload: true }},//预加载 pof 模块，防止刚登录进来，从首页跳转时丢失条件（跨组件间通信设置的1s清除消息，懒加载会导致初次模块加载时间长，跳转时消息已被清除）
      { path: 'bidding-procedure', loadChildren: './bidding-procedure/bidding-procedure.module#BiddingProcedureModule' },
      { path: 'CDVCatelogue', loadChildren: './CDVCatelogue/CDVCatelogue.module#CDVCatelogueModule' },
      { path: 'GGConfigLeadTime', loadChildren: './GGConfigLeadTime/GGConfigLeadTime.module#GGConfigLeadTimeModule' },
      { path: 'ServiceType', loadChildren: './ServiceType/ServiceType.module#ServiceTypeModule' },
      { path: 'MCType', loadChildren: './MCType/MCType.module#MCTypeModule' },
      { path: 'oncall-result', loadChildren: './oncall-result/oncall-result.module#OncallResultModule' },
      { path: 'eqbpinfo', loadChildren: './eqbpinfo/eqbpinfo.module#EqbpinfoModule' },
      { path: 'hveparts', loadChildren: './hveparts/hveparts.module#HVEPartsModule' },
      { path: 'SalesChannel', loadChildren: './SalesChannel/SalesChannel.module#SalesChannelModule' },
      { path: 'SAPEQ', loadChildren: './SAPEQ/SAPEQ.module#SAPEQModule' },
      { path: 'SAPDV', loadChildren: './SAPDV/SAPDV.module#SAPDVModule' },
      { path: 'oncall-result', loadChildren: './oncall-result/oncall-result.module#OncallResultModule' },
      { path: 'pss', loadChildren: './pss/pss.module#PSSModule' },
      { path: 'sapmctype', loadChildren: './SAPmcType/SAPmcType.module#SAPmcTypeModule' },
      { path: 'location', loadChildren: './location/location.module#LocationModule' },
      { path: 'CallNotification', loadChildren: './CallNotification/CallNotification.module#CallNotificationModule' },
      { path: 'MTAAllBU', loadChildren: './MTAAllBU/MTAAllBU.module#MTAAllBUModule' },
      { path: 'CustomerBySales', loadChildren: './CustomerBySales/CustomerBySales.module#CustomerBySalesModule' },
      { path: 'NTeam', loadChildren: './NTeam/NTeam.module#NTeamModule' },
      { path: 'Taskinfo', loadChildren: './Taskinfo/Taskinfo.module#TaskinfoModule' },
      { path: 'SalesProgress', loadChildren: './SalesProgress/SalesProgress.module#SalesProgressModule' },
      { path: 'DeliveryProgress', loadChildren: './Delivery/Delivery.module#DeliveryModule' },
      { path: 'download-excel', loadChildren: './download-excel/download-excel.module#DownloadExcelModule' },
      { path: 'ProjectDownload', loadChildren: './ProjectDownload/ProjectDownload.module#ProjectDownloadModule' },
      { path: 'ProjectDeletedReport', loadChildren: './ProjectDeletedReport/ProjectDeletedReport.module#ProjectDeletedReportModule' },
      { path: 'SalesBlock', loadChildren: './block/block.module#BlockModule' },
      { path: 'CustomerByLowMargin', loadChildren: './CustomerByLowMargin/CustomerByLowMargin.module#CustomerByLowMarginModule' },
      { path: 'EquipmentLowMargin', loadChildren: './equipment-low-margin/equipment-low-margin.module#EquipmentLowMarginModule' },
      { path: 'MCLeads', loadChildren: './MCLeads/MCLeads.module#MCLeadsModule' },
      { path: 'BackUpProject', loadChildren: './BackUpProject/BackUpProject.module#BackUpProjectModule' },
      { path: 'EQByBPUser', loadChildren: './EQByBPUser/EQByBPUser.module#EQByBPUserModule' },
      { path: 'NForecastReason', loadChildren: './NForecastReason/NForecastReason.module#NForecastReasonModule' },
      { path: 'report', loadChildren: './report/report.module#ReportModule' },
      { path: 'Area', loadChildren: './Area/Area.module#AreaModule' },
      { path: 'Customer-SubRegion', loadChildren: './Customer-SubRegion/Customer-SubRegion.module#CustomerSubRegionModule' },
      { path: 'Customer_Segmentation', loadChildren: './Customer_Segmentation/Customer_Segmentation.module#CustomerSegmentationModule' },
      { path: 'Equipment_Info', loadChildren: './Equipment_Info/Equipment_Info.module#EquipmentInfoModule' },
      { path: 'ISO-ISM', loadChildren: './ISO-ISM/ISO-ISM.module#ISOISMModule' },
      { path: 'backup', loadChildren: './backup/backup.module#BackupModule' },
    ]
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: 'home', }
];